import React from 'react';
import PropTypes from 'prop-types';
import {formatDate, getUnitDates, progressPercent} from 'utils/date_normalizer';
import moment from 'moment';
import style from './unit_progress_bar.styl';
const cn = require('classnames/bind').bind(style);

class UnitProgressBar extends React.Component {

  render() {
    const { unit, role, teacherLinks, palFirstName } = this.props;
    const dates = getUnitDates(unit, role, palFirstName);
    let today = new Date();
    let closestDate;
    let nextDate;
    for(let i = 0; i < dates.length; i++) {
      let date = new Date(dates[i].value);
      if(date > today) {
        closestDate = date;
        break;
      }
    }
    const inProgress = moment(unit.startDate) < Date.now();
    const screenWidth = window.innerWidth;
    let isMobile = false;
    if (/Android|webOS|iPhone|BlackBerry/i.test(navigator.userAgent)) {
      isMobile = true;
    }
    const width = progressPercent({
      approvalData: this.props.progress,
      currentUnitId: unit._id,
      dates,
      isDefaultUnit: unit.isDefaultUnit,
      role,
    });

    return (
        <div className={cn('unit-progress-bar')}>
          <div className={cn('header')}>
            <div className={cn('title')}>{t('unitProgressBar.currentUnitProgress')}</div>
            {teacherLinks &&
            <div className={cn('options')}>
              <a onClick={teacherLinks.onEditUnitClick}>{t('unitProgressBar.editUnit')}</a>
              <span className={cn('dashed')}/>
              <a onClick={teacherLinks.onGetTeacherGuideClick}>
                <span className={cn('icon-guide')}/>
                <span>{t('unitProgressBar.teacherGuide')}</span>
              </a>
            </div>
            }
          </div>

          {
            isMobile ? <div className={cn('time-bigbox')}>
              {dates.map(date => {
                return (
                  <div className={cn('time-box')} key={date.id +'1'}>
                    <div>
                      <div className={cn('year')}>{date.value && formatDate(date.value).split(' ')[1]}</div>
                      <div className={cn('year')}>{date.value && formatDate(date.value).split(' ')[0]}</div>
                    </div>
                    <div className={cn('content')}>
                      {
                        closestDate && date.value && formatDate(date.value).split(' ')[1] == closestDate.getDate() && new Date(date.value).getMonth() + 1 == closestDate.getMonth() + 1 ? <span className={cn('roundPurple')}></span> : <span className={cn('round')}></span>
                      }
                      <div className={cn('move')}>
                        {/*<div className={cn('title1')}>*/}
                        {/*  <div className={cn('h1')}>{date.label}</div>*/}
                        {/*</div>*/}
                        <div className={cn('box')}>{date.label}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div> : null
          }
          {
            isMobile ? null : <div className={cn('bar')}>
              <div className={cn('progress', {'not-in-progress': !inProgress})} style={{width}}/>
            </div>
          }
          {
            isMobile ? null : <div className={cn('dates', {'not-in-progress': !inProgress})}>
              {dates.map(date => {
                return (
                  <div className={cn('date-container')} key={date.id}>
                    <div className={cn('date')}>
                      <div className={cn('label')}>{date.label}</div>
                      <div className={cn('value')}>
                        {!date.value &&
                        <div className={cn('not-chosen')}>{t('unitProgressBar.not')}</div>
                        }
                        {date.value && formatDate(date.value)}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          }

        </div>
    );
  }
}
UnitProgressBar.displayName = "UnitProgressBar";
UnitProgressBar.propTypes = {
  unit: PropTypes.object.isRequired,
  progress: PropTypes.object.isRequired,
  role: PropTypes.oneOf(['teacher', 'mentor', 'student']).isRequired,
  teacherLinks: PropTypes.shape({
    onEditUnitClick: PropTypes.func.isRequired,
    onGetTeacherGuideClick: PropTypes.func.isRequired
  }),
};

module.exports = UnitProgressBar;