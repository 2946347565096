import React from "react";

const style = require('./forgot_username.styl');
const cn = require('classnames/bind').bind(style);
const {Component} = require('react');
const store = require('store');
//const {build} = require('utils/container_helpers');
const usersService = require('services/users_service');
const {redirect} = require('actions/routing');

const Card = require('components/shared/card');
const Button = require('components/shared/button');


class ForgotUsername extends Component {

    constructor(props) {
        super(props);
        this.state = {
            emailAddress: "",
            success: false,
            error: false,
            query: props.location.query
        };
    }

    navigateToLoginModal() {
        let next = '/?show_login=true';
        if (this.state.query && this.state.query.next) {
            next = this.state.query.next;
        }
        store.dispatch(redirect(next));
    }

    updateEmailAddress(ev) {
        this.setState({emailAddress: ev.target.value, error: false});
    }
    submitEmailAddress(ev) {
        ev.preventDefault();
        if (ev.target.form.reportValidity()) {
            usersService.sendRecoverUsernameEmail(this.state.emailAddress)
            .then((resp) => {
                this.setState({success: true, error: false});
            })
            .catch((error) => {
                this.setState({error: true});
            });
        }
    }

    render() {
        const tenant = store.getState().store.tenant;
        let appName = "Cricket Together";
        let contactEmail = "support@crickettogether.com"

        console.log(this.props.location.query);

        if (tenant.data)  {
            appName = tenant.data.name || appName
            contactEmail = tenant.data.contactEmail || contactEmail
        }

        return (
            <Card>
                <div className={cn('container')}>
                    {!this.state.success &&
                        <form className={cn('form')}>
                            <div className={cn('instructions')}>{t('forgotUsername.instructions',{appName})}</div>
                            <div className={cn('body')}>
                              <div className={cn('emailContent')}>
                              <label htmlFor="emailAddress" className={cn('label')}>Email Address</label>
                              <input name="emailAddress" className={cn('input')} type="email" autoFocus="true" onChange={(ev) => this.updateEmailAddress(ev)} minLength="6" value={this.state.emailAddress}/>
                              </div>
                              <div className={this.state.emailAddress.length > 0 ? cn('button') : cn('button1')}>
                                <Button enabled={this.state.emailAddress.length > 0} onClick={(ev)=> this.submitEmailAddress(ev)} submit={true} type="regular">Submit</Button>
                              </div>
                            </div>
                            {this.state.error &&
                                <div className={cn('error')}>{t('forgotUsername.error')}</div>
                            }
                        </form>

                    }
                    {this.state.success &&
                        <div className={cn('success')}>
                            <div className={cn('instructions')}>{t('forgotUsername.success1')} <a href={"mailto:"+contactEmail}>{contactEmail}</a>. {t('forgotUsername.success2')}</div>
                            <Button onClick={(ev)=> this.navigateToLoginModal()} submit={true} type="regular">{t('forgotUsername.signInBtn')}</Button>
                        </div>
                    }
                </div>
            </Card>
        );
    }
}

module.exports = ForgotUsername;
