_ = require 'lodash'
style = require './classroom.styl'
e = require('react-e/bind') style
moment = require 'moment'


getMentorName = (user) -> user?.displayName or user?.username


getStudentName = (user) -> "#{user?.profile.firstname} #{user?.profile.lastname}"


displayNumberLetters = (id, messages) ->
  numberLetters = getLetters(id, messages).length
  disabled = numberLetters is 0
  e 'a.cell_text', className: {disabled: disabled}, key: id, numberLetters


sortByDate = (letters) ->
  _.sortBy letters, (letter) -> moment letter.created


getLetters = (id, messages) ->
  sortByDate _.filter(messages, (m) -> m?.from?._id is id and m.status is 'approved')


mergeLetters = (studentLetters, mentorLetters) ->
  sortByDate studentLetters.concat(mentorLetters).filter((message) -> message.status isnt 'rejected')


ifPresent = (role, value) ->
  if role? then value else '-'


mapDataToRow = ({mentor, permission, student}, messages, classroom) ->
  permissionFormType = classroom.permissionFormType
  permissionState = '-'
  if permissionFormType is 'esign' and (permission is undefined or permission.status is 'new')
    if student.profile.parentname and student.profile.parentemail
      permissionState = 'pending'
    else
      permissionState = 'review'
  else if permissionFormType is 'paper' and (permission is undefined or permission.status is 'new')
    permissionState = 'paperPending'
  else if permission
    permissionState = permission.status
  row =
    mentor: ifPresent mentor, e('a.cell_text', key: mentor?.id, getMentorName mentor)
    mentorLetters: ifPresent mentor, displayNumberLetters(mentor?.id, messages)
    permission: permissionState
    student: ifPresent student, e('a.cell_text', key: student?.id, getStudentName student)
    studentLetters: ifPresent student, displayNumberLetters(student?.id, messages)
  return row

module.exports = {
  getLetters
  mapDataToRow
  mergeLetters
}
