// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".e46f5551338540a164b03c8a5435925b-styl {\n  display: flex;\n  margin-top: 1.25rem;\n}\n.e46f5551338540a164b03c8a5435925b-styl button {\n  font-size: 0.6875rem;\n}\n@media (max-width: 950px) {\n  .e46f5551338540a164b03c8a5435925b-styl button {\n    font-size: 15px;\n  }\n}\n.e46f5551338540a164b03c8a5435925b-styl ._608084d061b9cb1e709a1417ee56cf3f-styl {\n  color: var(--theme-blue-light, #00aff2);\n  font-family: Montserrat;\n  font-size: 0.75rem;\n  line-height: 2.25;\n  margin-left: 1rem;\n}\n@media (max-width: 950px) {\n  .e46f5551338540a164b03c8a5435925b-styl ._608084d061b9cb1e709a1417ee56cf3f-styl {\n    display: flex;\n    align-items: center;\n    font-size: 15px;\n    margin-left: 10px;\n    font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", Roboto, \"Helvetica Neue\", Arial, sans-serif, \"Apple Color Emoji\", \"Segoe UI Emoji\", \"Segoe UI Symbol\";\n  }\n}\n", "",{"version":3,"sources":["webpack://cricket-together/app/components/shared/article_preview/article_preview.styl"],"names":[],"mappings":"AAEA;EACE,aAAQ;EACR,mBAAW;AADb;AAGE;EACE,oBAAU;AADd;AAEwC;EAAA;IAClC,eAAU;EACd;AACF;AAAE;EACE,uCAA8C;EAC9C,uBAAY;EACZ,kBAAU;EACV,iBAAY;EACZ,iBAAY;AAEhB;AADwC;EAAA;IAClC,aAAQ;IACR,mBAAY;IACZ,eAAU;IACV,iBAAY;IACZ,iKAAyB;EAI7B;AACF","sourcesContent":["@import 'variables'\n\n.article-links\n  display flex\n  margin-top 1.25rem\n\n  button\n    font-size .6875rem\n    @media (max-width: $max-width-iphone)\n      font-size 15px\n\n  .details\n    color var(--theme-blue-light,$theme-blue-light)\n    font-family Montserrat\n    font-size .75rem\n    line-height 2.25\n    margin-left 1rem\n    @media (max-width: $max-width-iphone)\n      display flex\n      align-items center\n      font-size 15px\n      margin-left 10px\n      font-family -apple-system,BlinkMacSystemFont,\"Segoe UI\",Roboto,\"Helvetica Neue\",Arial,sans-serif,\"Apple Color Emoji\",\"Segoe UI Emoji\",\"Segoe UI Symbol\"\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"article-links": "e46f5551338540a164b03c8a5435925b-styl",
	"details": "_608084d061b9cb1e709a1417ee56cf3f-styl"
};
module.exports = ___CSS_LOADER_EXPORT___;
