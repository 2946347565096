/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS103: Rewrite code to no longer use __guard__, or convert again using --optional-chaining
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
let Component, createFactory, PropTypes;
({Component, createFactory} = require('react')) && (PropTypes = require('prop-types'));
const style = require('./footer.styl');
const e = require('react-e/bind')(style);
const {map} = require('lodash');

class Footer extends Component {
  static initClass() {
  
    this.prototype._userNavLinksDefault = [{
        name: 'Cricket Media',
        url: 'http://www.cricketmedia.com'
      }
      , {
        name: 'Products',
        url: 'http://www.cricketmedia.com/childrens-magazines'
      }
      , {
        name: 'Store',
        url: 'http://shop.cricketmedia.com/'
      }
      , {
        name: 'Licensing',
        url: 'http://www.cricketmedia.com/licensing'
      }
      , {
        name: 'Contact Us',
        url: 'http://www.cricketmedia.com/contact-us'
      }
      , {
        name: 'Terms of Use',
        url: 'http://www.cricketmedia.com/terms'
      }
    ];
  
    this.prototype._cmLogoDefault = {
      name: 'Crick Media',
      url: '/assets/cm-logo.svg'
    };
  }

  _loggedIn() {
    return __guard__(this.props.session != null ? this.props.session.data : undefined, x => x.login);
  }


  _renderUserLink(link) {
    return e('li.nav-link',
      e('a.nav-link-text', {href: link.url, target: '_blank'}, link.name));
  }

  constructor(props) {
    super(props);
    this.state = {
      isMobile: /Android|webOS|iPhone|BlackBerry/i.test(navigator.userAgent)
    };
  }

  render() {
    const navLinks = (this.props.tenant.data.pageFooterConfig != null ? this.props.tenant.data.pageFooterConfig.navLinks : undefined) != null ? (this.props.tenant.data.pageFooterConfig != null ? this.props.tenant.data.pageFooterConfig.navLinks : undefined) : this._userNavLinksDefault;
    const cmLogo =  Object.assign({}, this._cmLogoDefault);
    const copyrightText = this.props.tenant.data.copyrightText != null ? this.props.tenant.data.copyrightText : '© CricketMedia, Inc. All Rights Reserved.';

    if (__guard__(__guard__(this.props.tenant != null ? this.props.tenant.data : undefined, x1 => x1.pageFooterConfig), x => x.cmLogo)) {
      cmLogo.url = (this.props.tenant.data.pageFooterConfig.cmLogo != null ? this.props.tenant.data.pageFooterConfig.cmLogo.url : undefined) || cmLogo.url;
      cmLogo.name = (this.props.tenant.data.pageFooterConfig.cmLogo != null ? this.props.tenant.data.pageFooterConfig.cmLogo.name : undefined) || cmLogo.name;
    }

    if (__guard__(__guard__(this.props.classroom != null ? this.props.classroom.data : undefined, x3 => x3.pageFooterConfig), x2 => x2.cmLogo)) {
      cmLogo.url = (this.props.classroom.data.pageFooterConfig.cmLogo != null ? this.props.classroom.data.pageFooterConfig.cmLogo.url : undefined) || cmLogo.url;
      cmLogo.name = (this.props.classroom.data.pageFooterConfig.cmLogo != null ? this.props.classroom.data.pageFooterConfig.cmLogo.name : undefined) || cmLogo.name;
    }

    return this.state.isMobile ? e('footer.page-footer',
        !true && !this._loggedIn() ? // suppress the nav links for now for Privo compliance...
            e('ul.nav', ...Array.from((map(navLinks, this._renderUserLink)))) : undefined,
        e('.left',
            e('img.cricket-media-logo', {alt: cmLogo.name, src: cmLogo.url}),
            this.props.tenant.data.showCOPPASeal ?
                e('a.privo-logo', {href: 'https://cert.privo.com/#/companies/cricket_media', target: '_blank'}) : undefined),
        e('.right',
            e('.nav-link.privacy-policy',
                e('a.nav-link-text',
                    {href: 'http://cricketmedia.com/privacy-privo', target: '_blank'}, 'Privacy Policy')
            ),
            e('small.copyright', copyrightText))) :  e('footer.page-footer',
      !true && !this._loggedIn() ? // suppress the nav links for now for Privo compliance...
        e('ul.nav', ...Array.from((map(navLinks, this._renderUserLink)))) : undefined,
      e('.left',
        e('img.cricket-media-logo', {alt: cmLogo.name, src: cmLogo.url}),
        e('small.copyright', copyrightText)  ),
      e('.right',
        e('.nav-link.privacy-policy',
          e('a.nav-link-text',
            {href: 'http://cricketmedia.com/privacy-privo', target: '_blank'}, 'Privacy Policy')
        ),
        this.props.tenant.data.showCOPPASeal ?
          e('a.privo-logo', {href: 'https://cert.privo.com/#/companies/cricket_media', target: '_blank'}) : undefined   ));

    // return e('footer.page-footer',
    //     !true && !this._loggedIn() ? // suppress the nav links for now for Privo compliance...
    //         e('ul.nav', ...Array.from((map(navLinks, this._renderUserLink)))) : undefined,
    //     e('.left',
    //         e('img.cricket-media-logo', {alt: cmLogo.name, src: cmLogo.url}),
    //         this.props.tenant.data.showCOPPASeal ?
    //           e('a.privo-logo', {href: 'https://cert.privo.com/#/companies/cricket_media', target: '_blank'}) : undefined),
    //     e('.right',
    //         e('.nav-link.privacy-policy',
    //             e('a.nav-link-text',
    //                 {href: 'http://cricketmedia.com/privacy-privo', target: '_blank'}, 'Privacy Policy')
    //         ),
    //         e('small.copyright', copyrightText)));
  }
}
Footer.initClass();

Footer.displayName = "Footer";

module.exports = Footer;

function __guard__(value, transform) {
  return (typeof value !== 'undefined' && value !== null) ? transform(value) : undefined;
}