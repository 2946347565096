_ = require 'lodash'
moment = require 'moment'
({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './unit_detail_date_picker.styl'
e = require('react-e/bind') style
DatePicker = createFactory require 'components/shared/date_picker'


class UnitDetailDatePicker extends Component

  @defaultProps:
    isEditable: true
    shouldOpenFirstPicker: false
    shouldStartWithToday: false
    wide: false


  @propTypes:
    dates: PropTypes.arrayOf PropTypes.shape(
      label: PropTypes.string.isRequired
      onChange: PropTypes.func
      value: PropTypes.object
    ).isRequired
    isEditable: PropTypes.bool
    shouldOpenFirstPicker: PropTypes.bool
    shouldStartWithToday: PropTypes.bool
    wide: PropTypes.bool

  _makeIsOutsideRange: (datePickerIndex) =>
    {dates, shouldStartWithToday} = @props
    minDate = _.max dates[0...datePickerIndex].map (dateObj) -> dateObj.value
    maxDate = _.min dates[(datePickerIndex + 1)...dates.length].map (dateObj) -> dateObj.value
    if shouldStartWithToday then minDate or= moment()
    (date) -> date < minDate or date > maxDate

  render: ->
    if @props.dates.length is 0
      e '.no-dates', t 'unitDetailDatePicker.addUnit'
    else
      e '.picker', (_.map @props.dates, @_renderDate)...


  _renderDate: ({initialVisibleMonthFallback, label, id, onChange, value}, i) =>
    e '.date-container',
      if /Android|webOS|iPhone|BlackBerry/i.test(navigator.userAgent) then null else e '.line-accent', ''
      if /Android|webOS|iPhone|BlackBerry/i.test(navigator.userAgent) then null else
        e '.date-title',
          e 'span', className: wide: @props.wide, label
      e '.date-picker',
        DatePicker
          date: value
          focused: @props.shouldOpenFirstPicker and i is 0
          id: "#{label}-#{i}"
          initialVisibleMonthFallback: initialVisibleMonthFallback
          isOutsideRange: @_makeIsOutsideRange i
          onSelected: onChange or -> ''
          readOnly: not @props.isEditable
          value: label
          isLast: if @props.dates.length == i + 1 then 1 else 0


UnitDetailDatePicker.displayName = "UnitDetailDatePicker"

module.exports = UnitDetailDatePicker
