style = require './header_js.styl'
e = require('react-e/bind') style
({Component} = require 'react') and (PropTypes = require 'prop-types')
_ = require 'lodash'


class Link extends Component

  constructor: ->
    super()
    @state = showDropdown: false


  @propTypes =
    link: PropTypes.shape
      active: PropTypes.bool
      hoverElement: PropTypes.element
      name: PropTypes.string.isRequired
      onClick: PropTypes.func.isRequired


  render: ->
    {link} = @props
    e '.link',
      className:
        active: link.active,
      onClick: (e) =>
        if link.hoverElement then @setState showDropdown: false
      onMouseOut: if link.hoverElement then => @setState showDropdown: false
      onMouseOver: if link.hoverElement then => @setState showDropdown: true
      e '.name',
        onClick: link.onClick
        style:
          color:if /Android|webOS|iPhone|BlackBerry/i.test(navigator.userAgent) then 'rgba( 0, 0, 0, 0)' else null,
          backgroundImage: if /Android|webOS|iPhone|BlackBerry/i.test(navigator.userAgent)
            if link.active then "url(#{link.image}-active.svg)" else "url(#{link.image}.svg)"
          else
            null
#          backgroundImage: if window.innerWidth < 767 then if link.active then "url(#{link.image})" else "url(#{link.image})" else null
        if /Android|webOS|iPhone|BlackBerry/i.test(navigator.userAgent) then '' else link.name

      e '.hover-element',
        className: ['hover-element-show': @state.showDropdown]
        link.hoverElement

Link.displayName = "Link"
module.exports = Link
