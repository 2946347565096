{Component, createFactory} = require 'react'
{build} = require 'utils/container_helpers'
{concat, filter, map, partition, sortBy, values} = require 'lodash'
{showDetail} = require 'utils/article_normalizer'
{redirect} = require 'actions/routing'
events = require '../events'
style = require './library.styl'
e = require('react-e/bind') style
exploreUnitsActions = require 'actions/explore_units'
ArticlePreview = createFactory require 'components/shared/article_preview/index.jsx'
ContentLoader = createFactory require 'components/shared/content_loader'
Carousel = createFactory require 'components/shared/carousel'

mapStateToProps = ({store}) ->
  units: store.unitTemplates

class Library extends Component

  componentDidMount: ->
    @props.dispatch exploreUnitsActions.fetch()


  _sortedUnits: ->
    units = values @props.units.data
    [introUnit, otherUnits] = partition units, 'isDefaultUnit'
    concat introUnit, sortBy(otherUnits, 'name')

  render: ->
    ContentLoader
      content: @props.units
      name: 'units'
      renderChild: =>
        e '.library',
          map @_sortedUnits(), (unit) =>
            if unit.content.length
              e '.section', key: unit._id,
                e '.header', unit.name
                e '.articles',
                if /Android|webOS|iPhone|BlackBerry/i.test(navigator.userAgent)
                  Carousel {},
                    map sortBy(unit.content, 'lexile'), (article) =>
                      e '.article', key: article._id,
                        ArticlePreview
                          article: article
                          image: article.image
                          onClickLink: =>
                            events.details article.title
                            showDetail
                              articleId: article._id
                              redirectTo: (path) => @props.dispatch redirect path
                              unitTemplateId: unit._id
                          onClickRead: -> events.readArticle article.title
                          scheme: 'plain'
                else
                  map sortBy(unit.content, 'lexile'), (article) =>
                    e '.article', key: article._id,
                      ArticlePreview
                        article: article
                        image: article.image
                        onClickLink: =>
                          events.details article.title
                          showDetail
                            articleId: article._id
                            redirectTo: (path) => @props.dispatch redirect path
                            unitTemplateId: unit._id
                        onClickRead: -> events.readArticle article.title
                        scheme: 'plain'

Library.displayName = "Library"

module.exports = build {
  component: Library,
  mapStateToProps
}
