import React from "react";

const style = require('./forgot_password.styl');
const cn = require('classnames/bind').bind(style);
const {Component} = require('react');
const store = require('store');
//const {build} = require('utils/container_helpers');
const usersService = require('services/users_service');
const {redirect} = require('actions/routing');

const Card = require('components/shared/card');
const Button = require('components/shared/button');


class ForgotPassword extends Component {

    constructor(props) {
        super(props);
        this.state = {
            username: "",
            success: false,
            error: false,
            next: props.location.query.next ? props.location.query.next : "/"
        };
    }

    navigateToHomePage() {
        store.dispatch(redirect(this.state.next));
    }

    updateUsername(ev) {
        this.setState({username: ev.target.value, error: false});
    }

    submitUsername(ev) {
        ev.preventDefault();
        if (ev.target.form.reportValidity()) {
            usersService.sendRecoverPasswordEmail(this.state.username, this.state.next)
            .then((resp) => {
                this.setState({success: true, error: false});
            })
            .catch((error) => {
                this.setState({error: true});
            });
        }
    }

    render() {
        const tenant = store.getState().store.tenant;
        let appName = "Cricket Together";
        let contactEmail = "support@crickettogether.com"

        if (tenant.data)  {
            appName = tenant.data.name || appName;
            contactEmail = tenant.data.contactEmail || contactEmail
        }

        return (
            <Card>
                <div className={cn('container')}>
                    {!this.state.success &&
                        <form className={cn('form')}>
                            <div className={cn('instructions')}>{t('forgotPassword.instructions',{appName})}</div>
                            <div className={cn('body')}>
                              <div className={cn('userContent')}>
                              <label htmlFor="username" className={cn('label')}>Username</label>
                              <input name="username" className={cn('input')} type="text" autoFocus="true" onChange={(ev) => this.updateUsername(ev)} minLength="1" value={this.state.username}/>
                              </div>
                              <div className={this.state.username.length > 0 ? cn('button') : cn('button1')}>
                                <Button enabled={this.state.username.length > 0} onClick={(ev)=> this.submitUsername(ev)} submit={true} type="regular">Submit</Button>
                              </div>
                            </div>
                            {this.state.error &&
                                <div className={cn('error')}>{t('forgotPassword.error')}</div>
                            }
                        </form>

                    }
                    {this.state.success &&
                        <div className={cn('success')}>
                            <div className={cn('instructions')}>{t('forgotPassword.success1')}  <a href={"mailto:"+contactEmail}>{contactEmail}</a>.  {t('forgotPassword.success2')}</div>
                            <Button onClick={(ev)=> this.navigateToHomePage()} submit={true} type="regular">OK</Button>
                        </div>
                    }
                </div>
            </Card>
        );
    }
}

module.exports = ForgotPassword;
