import moment from "moment";

export default class UserHelpers {

  static getMissingProfileFields(user) {
    const requiredFields = ['firstname', 'lastname', 'email', 'country', 'city', 'state'];
    if (user.country === 'US') {
      requiredFields.push('zipcode');
    }

    const missingFields = requiredFields.filter((field) => !user[field] || user[field].length === 0);
    return missingFields;
  }

  static hasAllRequiredProfileFields(user) {
    const requiredFields = ['firstname', 'lastname', 'email', 'country', 'city', 'state'];
    if (user.country === 'US') {
      requiredFields.push('zipcode');
    }
    const hasRequired = requiredFields.map((field) => !!user[field] && user[field].length > 0);
    return hasRequired.reduce((acc, val) => acc && val, true);
  }

  // Returns a flattened version of the user model with additional derived state:
  //
  //             email - first (default) email address
  //     emailVerified - first (default) email verification status
  //   backgroundCheck - current background check (first) with additional flags
  //         onboarded - onboarded field from service record
  // isReturningMentor - boolean if onboarded field has values
  //
  static getTransformedUser(user, sessions, classrooms, tenant) {
    if (!user) { throw new Error('user is required'); }

    sessions = sessions || [];
    const role = user.roles.find((x) => x === 'mentor' || x === 'student');

    const transformedUser = Object.assign({}, user, user.profile,
        (user.emails && user.emails.length) ? {
          email: user.emails[0].address,
          emailVerified: user.emails[0].verified,
        } : {});

    let backgroundCheck;
    if (user.backgroundChecks && user.backgroundChecks.history && user.backgroundChecks.history.length) {
      backgroundCheck = user.backgroundChecks.history[0];
      backgroundCheck.isExpired = backgroundCheck.expires && moment(backgroundCheck.expires).isBefore(moment());
      backgroundCheck.isValid = moment(backgroundCheck.expires).isAfter(moment());
      backgroundCheck.isSubmitted = ['created', 'submitted', 'invited'].includes(backgroundCheck.status);
    }
    transformedUser.backgroundCheck = backgroundCheck;

    if (tenant) {
      const tenantId = tenant.id;
      const record = _.find(user.cm_services, ['service', tenantId]);
      if (record) {
        const numTotalSessions = sessions.length;
        const numCompletedSessions = sessions.filter(x => x.status === 'completed').length;
        transformedUser.isReturningMentor = (numTotalSessions > 1) && (numCompletedSessions > 0);
        transformedUser.onboarded = (user.appState && user.appState.onboarded) || {};
        let trainingItems = [];
        if (tenant[role]) {
          trainingItems = tenant[role][ transformedUser.isReturningMentor ? "trainingReturning" : "trainingNew" ] || [];
        }
        const trainingWatched = trainingItems.map(x => {
          let trainingViewed = false;
          if (user.appState && user.appState.trainingViewed) {
            trainingViewed = (user.appState.trainingViewed[x.videoId] || false);
          }
          return trainingViewed;
        }).filter(x => x);
        transformedUser.trainingItems = trainingItems;
        transformedUser.trainingWatched = trainingWatched.length;
        transformedUser.trainingRequired = trainingItems.length;
        transformedUser.trainingIsComplete = trainingWatched.length === trainingItems.length;
      }
    }

    transformedUser.parentRegistration = user.parentRegistration;
    transformedUser.backgroundCheckRequired = !user.parentRegistration;

    if (classrooms && !(classrooms instanceof Array)) {
      const myID = user.id;
      const myRole = user.roles[0];
      const palRole = myRole === 'student' ? 'mentor' : 'student';

      const smgs = classrooms.studentMentorGroups.filter(group => group[myRole] === myID);
      if (smgs.length > 0) {
        const smg = smgs[0];
        const palID = smg[palRole];
        transformedUser.pal = classrooms[`${palRole}s`].filter(({id}) => id === palID)[0];
      }
    }

    return transformedUser;
  }
}

