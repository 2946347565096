import React from 'react';
import Button from 'components/shared/button';
import Login from 'components/shared/login';
import ModalWrapper from 'components/shared/modal/modal_wrapper';

const style = require('./landing.styl');
const cn = require('classnames/bind').bind(style);
const {build} = require('utils/container_helpers');
const sessionActions = require('actions/session');
const {redirect} = require('actions/routing');
const events = require('containers/header_nav_container/events');

const mapStateToProps = ({store}) => ({
  session: store.session,
  tenant: store.tenant
});

class Landing extends React.Component {

  componentDidMount() {
    return this.props.dispatch(sessionActions.fetchLocalToken());
  }

  onLoginSubmit(credentials) {
    events.clickedLogin();
    return this.props.dispatch(
        sessionActions.login(credentials, ({role, userId}) => {
          events.loginSuccess({role, userId});
          return this.props.dispatch(redirect('/'));
        })
    );
  }

  //
  // NOTE - We've added another ModalWrapper/Login combo here because it's the
  // simplest solution to having the landing page trigger it's own login, but it
  // doesn't seem ideal, at the very least we should try to wrap the whole thing
  // into a higher-level component so that we don't have to make redundant changes
  // to the same code, but maybe there's a way to trigger the login modal compnoent
  // that already exists on the page without going thru alot of React shenanigans...
  //
  render() {
    const {session, dispatch, tenant} = this.props;
    const loginLinkText = t('login.signIn');
    const appName = tenant.data.name || 'CricketTogether';
    const screenWidth = window.innerWidth;
    let isMobile = false;
    if (/Android|webOS|iPhone|BlackBerry/i.test(navigator.userAgent)) {
      isMobile = true;
    }
    const welcomeImage = tenant.data.welcomeImage || (isMobile ? 'assets/icons/smallhero@2x.jpg' : 'assets/hero@2x.jpg');
    
    const modalTrigger = <div className={cn('button')}><Button size='large' type='regular'>{loginLinkText}</Button></div>;
    return (
      <div className={cn('landing')}>
        <div className={cn('overlay')}>
          <h2 className={cn('title')}>{t('landing.welcome', {appName})}</h2>
        </div>
        <div className={cn('card','hero')} style={{backgroundImage:`url(${welcomeImage})`}} />
        <div className={cn('learn-more')}>
          <ModalWrapper onExit={() => dispatch(sessionActions.clearError())}
                        trigger={modalTrigger}>
            <Login error={session.error}
                   loading={session.loading}
                   loginText={loginLinkText}
                   showRegLink={tenant.data.showRegistrationLink}
                   tenant={tenant.data}
                   onSubmit={ (credentials) => this.onLoginSubmit(credentials) } />
          </ModalWrapper>
        </div>
      </div>
    );
  }
}

Landing.displayName = "Landing";

module.exports = build({
  component: Landing,
  mapStateToProps
});
