({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
Button = createFactory require 'components/shared/button'
EditorContainer = createFactory require 'containers/editor_container'
Editor = createFactory require 'components/shared/editor'
style = require './editor_view.styl'
e = require('react-e/bind') style
_ = require 'lodash'

class EditorView extends Component

  @propTypes =
    allowAttachments: PropTypes.bool
    events: PropTypes.object.isRequired
    feedback: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    initialEditorValue: PropTypes.string.isRequired
    mode: PropTypes.string.isRequired
    onCancelButtonClick: PropTypes.func.isRequired
    onEditorValueChange: PropTypes.func.isRequired
    onNextClick: PropTypes.func.isRequired
    reply: PropTypes.bool
    userType: PropTypes.string.isRequired


  _generateIntroductionText: ->
    text =
      if @props.mode is 'draft'
        if @props.reply then 'reply' else @props.userType
      else 'revise'
    t "pageLetterComposer.introduction.#{text}"


  render: ->
    nextButtonTitle = if @props.mode is 'draft'
      t 'letterComposerTabView.draftButtonTitle'
    else
      t 'letterComposerTabView.reviseAndSendButtonTitle'
    editorProps =
      allowAttachments: @props.allowAttachments or no
      initialValue: @props.initialEditorValue
      onValueChange: @props.onEditorValueChange
    e '.content',
      if @props.feedback
        e '.suggestion',
          e '.suggestion-title', t 'letterComposerTabView.feedbackTitleText'
          e '.suggestion-content', @props.feedback
      else
        e '.introduction', @_generateIntroductionText()
      e '.editor',
        if @props.allowAttachments then EditorContainer editorProps else Editor editorProps
      e '.buttons',
        e '.cancel-button',
          Button
            mode: 'iphone'
            onClick: =>
              @props.events.write.saveExit()
              @props.onCancelButtonClick()
            size: 'large'
            type: 'regular',
            t 'letterComposerTabView.saveAndExit'
        e '.next-button',
          Button
            onClick: @props.onNextClick
            size: 'large'
            type: 'regular',
            nextButtonTitle


EditorView.displayName = "EditorView"

module.exports = EditorView
