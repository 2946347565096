// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".d30efa181095591cb77353628936ad64-styl {\n  display: flex;\n  flex: 1 0 auto;\n}\n@media (max-width: 950px) {\n  .d30efa181095591cb77353628936ad64-styl {\n    flex: 1;\n    width: 100%;\n  }\n}\n", "",{"version":3,"sources":["webpack://cricket-together/app/components/letter_collection/letter_collection.styl"],"names":[],"mappings":"AAEA;EACE,aAAQ;EACR,cAAK;AADP;AAEsC;EAAA;IAClC,OAAK;IACL,WAAM;EACR;AACF","sourcesContent":["@import 'variables'\n\n.letter-collection\n  display flex\n  flex 1 0 auto\n  @media (max-width: $max-width-iphone)\n    flex 1\n    width 100%\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"letter-collection": "d30efa181095591cb77353628936ad64-styl"
};
module.exports = ___CSS_LOADER_EXPORT___;
