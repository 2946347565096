import React from 'react';
import PropTypes from 'prop-types';
const style = require('./letter_composer_tabs.styl');
const cn = require('classnames/bind').bind(style);
import Modal from 'components/shared/modal';
import Button from 'components/shared/button';

const ModalPlanLocked = ({onClose, onClickKeepPlanning, onClickContinue}) => {
  return (
      <Modal onClose={onClose}
             top={true}>
        <div className={cn('modal')}>
          <div className={cn('modal-header')}>
            {t('pageLetterComposer.modalPlanCompleteHeader')}
          </div>
          <div className={cn('modal-text')}>
            {t('pageLetterComposer.modalPlanCompleteText')}
          </div>
          <div className={cn('modal-buttons')}>
            <div className={cn('buttons')}>
              <div className={cn('button-left')}>
                <Button mode='iphone' size='large' type='regular'
                        onClick={onClickKeepPlanning}>
                  {t('pageLetterComposer.modalPlanCompleteKeepPlanning')}
                </Button>
              </div>
              <div className={cn('button-right')}>
                <Button size='large' type='regular'
                        onClick={onClickContinue}>
                  {t('pageLetterComposer.continue')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
  );
};

ModalPlanLocked.displayName = 'ModalPlanLocked';
ModalPlanLocked.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClickKeepPlanning: PropTypes.func.isRequired,
  onClickContinue: PropTypes.func.isRequired
};

export default ModalPlanLocked;
