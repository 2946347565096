({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
{formatLetterForModal} = require 'pages/teacher/classroom_unit/normalization_helper'
LetterModal = createFactory require 'components/letter_modal'
PrintLetters = createFactory require 'components/print_letters'
style = require './letter_collection.styl'
e = require('react-e/bind') style
_ = require 'lodash'


class LetterCollection extends Component

  @defaultProps =
    letterIndex: 0


  @propTypes =
    letters: PropTypes.arrayOf(PropTypes.shape
      created: PropTypes.string.isRequired
      from: PropTypes.shape(displayName: PropTypes.string).isRequired
      to: PropTypes.shape(displayName: PropTypes.string)
    ).isRequired


  render: ->
    letters = @props.letters.map formatLetterForModal
    e '.letter-collection',
      if /Android|webOS|iPhone|BlackBerry/i.test(navigator.userAgent)
        null
      else
        PrintLetters
          bulk: yes
          className: 'print-letters'
          id: @props.id
          letters: letters
      LetterModal
        indexOfMessageInConversation: @props.letterIndex + 1
        message: letters[@props.letterIndex]
        print: no
        totalMessagesInConversation: @props.letters.length


  shouldComponentUpdate: (newProps) ->
    lettersChange = not _.isEqual(@props.letters, newProps.letters)
    indexChange = not _.isEqual(@props.letterIndex, newProps.letterIndex)
    return lettersChange or indexChange

LetterCollection.displayName = "LetterCollection"

module.exports = LetterCollection
