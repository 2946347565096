import React from "react";
import PropTypes from 'prop-types';
import Button from 'components/shared/button';
import TextArea from 'react-textarea-autosize';
const style = require('./plan_content.styl');
const cn = require('classnames/bind').bind(style);

export default class PlanContent extends React.Component {

  constructor(props) {
    super(props);
    this.state = PlanContent.getInitialState(props);
  }

  // This is necessary to reset our state when the user chooses to reset or start over.
  // After clicking start over, the answers property will be reset to [].  If we detect
  // that our current answers property has length 0 and previously had length > 0 then
  // we know the user clicked to start over and we should reset the planner to its initial
  // state.

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.answers.length > 0 && this.props.answers.length === 0 ) {
      this.setState( PlanContent.getInitialState(this.props) );
    }
  }

  static getInitialState(props) {
    const { questions, answers } = props;
    const expanded = questions.map( () => false );
    const firstUnansweredIdx = answers.findIndex((answer) => !answer);
    expanded[(firstUnansweredIdx === -1) ? 0 : firstUnansweredIdx ] = true;
    const answered = questions.map( (question, idx) => {
      return !!answers[idx];
    })
    return { expanded, answered };
  }

  _collapseQuestion(index) {
    const {expanded} = this.state;
    expanded[index] = false;
    return this.setState({expanded} );
  }

  onChangeAnswer(index, val) {
    const {answers, onChange} = this.props;
    answers[index] = val;
    onChange(answers);
  }

  onClickAnswered(index) {
    if (this.props.isPlanEditable) {
      const {expanded, answered} = this.state;
      expanded[index] = true;
      answered[index] = false;
      return this.setState({expanded, answered} );
    } else {
      return this._toggleView(index);
    }
  }

  onClickNextQuestion(index) {
    this.props.events.write.nextQuestion();

    const {questions} = this.props;
    const {expanded, answered} = this.state;
    expanded[index] = false;
    answered[index] = true;

    for (let i = (index + 1) % questions.length; i <= questions.length; i++) {
      if (!answered[i]) {
        expanded[i] = true;
        break;
      }
    }
    return this.setState({expanded, answered} );
  }

  _toggleView(index) {
    const { expanded } = this.state;
    expanded[index] = !expanded[index];
    return this.setState({expanded} );
  }

  renderAnsweredQuestion(index) {
    const { questions, answers, isPlanEditable } = this.props;
    const question = questions[index];
    const answer = answers[index];
    const active = this.state.expanded[index];
    const { subtitle, title } = question;

    return (
        <div className={cn('answered-question-container', {active})}
             key={`answered-${index}`}
             onClick={() => this.onClickAnswered(index)}>
          {isPlanEditable &&
          <div className={cn('edit-icon')}/>
          }
          <div className={cn('answered-question-prompt')}>
            {title &&
            <div className={cn('plan-question-title')}>{title}</div>
            }
            <div className={cn('plan-question-subtitle')}>{subtitle}</div>
          </div>
          <div className={cn('answered-question-answer')}>
            {answer}
          </div>
        </div>
    );
  }

  renderAnsweredQuestions() {
    const {isPlanEditable, palFirstName, firstName} = this.props;
    const answeredQuestions = this.state.answered.map((answered, idx) => answered ? idx : null ).filter(x => x !== null);
    if ((answeredQuestions.length === 0) && !isPlanEditable) { return; }
    return (
        <div className={cn('plan-letter-builder')}>
          <div className={cn('plan-greeting')}>{t('letterComposerTabView.greeting', {name: palFirstName})}</div>
          {answeredQuestions.length === 0 &&
          <div className={cn('plan-answer-placeholder')}>{t('letterComposerTabView.planAnswerPlaceholder')}</div>
          }
          {answeredQuestions.length > 0 &&
          <div className={cn('plan-answered')}>
            {answeredQuestions.map((idx) => {
              return this.renderAnsweredQuestion(idx);
            })}
          </div>
          }
          <div className={cn('plan-closing')}>
            <div className={cn('plan-sincerely')}>{t('letterComposerTabView.sincerely')}</div>
            <div className={cn('plan-signature')}>{firstName}</div>
          </div>
        </div>
    );
  }


  renderCollapsedPlanQuestion(index) {
    const { questions } = this.props;
    const question = questions[index];
    const { title } = question;
    return (
        <div className={cn('plan-question-container')} key={`collapsed-${index}`}
             onClick={() => this._toggleView(index)}>
          <div className={cn('plan-title-container')}>
            {title &&
            <div className={cn('plan-question-title')}>{title}</div>
            }
          </div>
        </div>
    );
  }


  renderExpandedPlanQuestion(index) {
    const {questions, answers, isPlanEditable} = this.props;
    const question = questions[index];
    const answer = answers[index];
    const {bullets, postBullet, subtitle, title} = question;
    let isMobile = false;
    if (/Android|webOS|iPhone|BlackBerry/i.test(navigator.userAgent)) {
      isMobile = true;
    }
    return (
        <div className={cn('plan-question-container', 'active')} key={`expanded-${index}`}
             onClick={() => this._collapseQuestion(index)}>
          <div className={cn('plan-title-container')}>
            {title &&
            <div className={cn('plan-question-title')}>{title}</div>
            }
            {subtitle &&
            <div className={cn('plan-question-subtitle')}>{subtitle}</div>
            }
            {bullets &&
            <ul className={'plan-question-bullets'}>
              {bullets.map(bullet => <li className={cn('bullet')}>{bullet}</li>)}
            </ul>
            }
            {postBullet &&
            <div className={cn('plan-question-postBullet')}>{postBullet}</div>
            }
          </div>
          <div className={cn('plan-question-answer-container')}>
            <TextArea value={answer || ""}
                      onChange={(event) => { this.onChangeAnswer(index, event.target.value) }}
                      onClick={(event) => { return event.stopPropagation() }}
                      placeholder={isPlanEditable ? t('pageLetterComposer.placeholder') : ''}
                      readOnly={!isPlanEditable} />
            {isPlanEditable &&
            <div className={cn('plan-question-actions')}>
              <Button mode='transparent'
                      onClick={() => this.onClickNextQuestion(index)}
                      size='small'
                      type='regular'>
                {
                  isMobile ? '' : t('letterComposerTabView.next')
                }
              </Button>
            </div>
            }
          </div>
        </div>
      );
  }



  renderUnansweredQuestions() {
    const {questions, isPlanEditable} = this.props;
    let unansweredQuestions = this.state.answered.map((answered, idx) => !answered ? idx : null).filter((x) => x !== null);
    if (isPlanEditable) {
      return (
          <div className={cn('unanswered-questions')}>
            {unansweredQuestions.map((idx) => {
              const expanded = this.state.expanded[idx];
              if (expanded) {
                return this.renderExpandedPlanQuestion(idx);
              } else {
                return this.renderCollapsedPlanQuestion(idx);
              }
            })}
          </div>
      );
    } else {
      return (
        <div className={cn('locked-unanswered-questions')}>
          {unansweredQuestions.map((index) => {
            const active = this.state.expanded[index];
            const question = questions[index];
            const {bullets, postBullet, subtitle, title} = question;
            return (
              <div className={cn('locked-question-container', {active})}
                   key={`unanswered-${index}`}
                   onClick={() => this._toggleView(index)}>
                {title &&
                <div className={cn('plan-question-title')}>{title}</div>
                }
                {subtitle &&
                <div className={cn('plan-question-subtitle')}>{subtitle}</div>
                }
                {bullets &&
                <ul className={cn('plan-question-bullets')}>
                  {bullets.map((bullet, index) => <li className={cn('bullet')} key={`bullet-${index}`}>{bullet}</li>)}
                </ul>
                }
                {postBullet &&
                <div className={cn('plan-question-postBullet')}>{postBullet}</div>
                }
              </div>
            );
          })}
        </div>
      );
    }
  }

  render() {
    const {isPlanEditable, feedback, onClickReset, onClickCancel, onClickNext} = this.props;
    return (
        <div className={cn('plan-content', {locked: !isPlanEditable})}>
          <div className={cn('plan-introduction')}>
            {!isPlanEditable &&
            <div className={cn('text-container')}>
              <span className={'text'}>{t('pageLetterComposer.planLocked')}</span>
            </div>
            }
            {isPlanEditable &&
            <div className={cn('text-container')}>
              <span className={cn('text')}>{t('pageLetterComposer.planUnlocked')}</span>
            </div>
            }
          </div>
          <div className={cn('plan-questions')}>
            {this.renderAnsweredQuestions()}
            {this.renderUnansweredQuestions()}
          </div>
          <div className={cn('plan-buttons')}>
            {!feedback &&
            <div className={cn('plan-reset-button')}>
              <Button enabled
                      onClick={onClickReset}
                      size='large'
                      type='regular'>
                {t('letterComposerTabView.resetButtonTitle')}
              </Button>
            </div>
            }
            {isPlanEditable && onClickCancel &&
            <div className={cn('plan-cancel-button')}>
              <Button enabled
                      mode='iphone'
                      onClick={onClickCancel}
                      size='large'
                      type='regular'>
                {t('letterComposerTabView.saveAndExit')}
              </Button>
            </div>
            }
            <div className={cn('plan-next-step-button')}>
              <Button enabled
                      onClick={onClickNext}
                      size='large'
                      type='regular'>
                {t('letterComposerTabView.planButtonTitle')}
              </Button>
            </div>
          </div>
        </div>
    );
  }
}

PlanContent.displayName = "PlanContent";
PlanContent.propTypes = {
  questions: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    bullets: PropTypes.array,
    postBullet: PropTypes.string
  })).isRequired,
  answers: PropTypes.array,
  events: PropTypes.object.isRequired,
  feedback: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  firstName: PropTypes.string.isRequired,
  palFirstName: PropTypes.string.isRequired,
  isPlanEditable: PropTypes.bool.isRequired,
  onClickCancel: PropTypes.func,
  onClickReset: PropTypes.func.isRequired,
  onClickNext: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
