// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cbd2362f70be31e9032dbb99cc037c42-styl {\n  color: var(--theme-white, #fff);\n  margin: 0 1.25rem 4rem;\n}\n@media (max-width: 950px) {\n  .cbd2362f70be31e9032dbb99cc037c42-styl {\n    margin: 0rem;\n  }\n}\n.ef25878c2eea45d08933a207533d5543-styl {\n  margin-top: 2rem;\n}\n._511d1414b5a5eb50d6728c0486f6e3a6-styl {\n  margin-top: 2rem;\n}\n@media (max-width: 950px) {\n  ._511d1414b5a5eb50d6728c0486f6e3a6-styl {\n    margin-top: 0.5rem;\n  }\n}\n.c667f26f9d9400ade244bcab707f590c-styl {\n  margin-top: 5rem;\n}\n@media (max-width: 950px) {\n  .c667f26f9d9400ade244bcab707f590c-styl {\n    margin-top: 1rem;\n  }\n}\n", "",{"version":3,"sources":["webpack://cricket-together/app/components/home/home.styl"],"names":[],"mappings":"AAEA;EACE,+BAAoC;EACpC,sBAAO;AADT;AAEsC;EAAA;IAClC,YAAO;EACT;AACF;AADA;EACE,gBAAW;AAGb;AADA;EACE,gBAAW;AAGb;AAFsC;EAAA;IAClC,kBAAW;EAKb;AACF;AAJA;EACE,gBAAW;AAMb;AALsC;EAAA;IAClC,gBAAW;EAQb;AACF","sourcesContent":["@import 'variables'\n\n.home\n  color var(--theme-white,$theme-white)\n  margin 0 1.25rem 4rem\n  @media (max-width: $max-width-iphone)\n    margin 0rem\n.progress-bar\n  margin-top 2rem\n\n.action-items\n  margin-top 2rem\n  @media (max-width: $max-width-iphone)\n    margin-top 0.5rem\n\n.footer-cards\n  margin-top 5rem\n  @media (max-width: $max-width-iphone)\n    margin-top 1rem\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home": "cbd2362f70be31e9032dbb99cc037c42-styl",
	"progress-bar": "ef25878c2eea45d08933a207533d5543-styl",
	"action-items": "_511d1414b5a5eb50d6728c0486f6e3a6-styl",
	"footer-cards": "c667f26f9d9400ade244bcab707f590c-styl"
};
module.exports = ___CSS_LOADER_EXPORT___;
