{createFactory} = require 'react'
createClass = require 'create-react-class'
PropTypes = require 'prop-types'
Modal = createFactory require './'
#e = require 'react-e'
style = require './modal.styl'
e = (require 'react-e/bind') style

ModalWrapper = createClass

  getDefaultProps: ->
    fullSize: no


  getInitialState: ->
    show: @props.show


  propTypes:
    eReader: PropTypes.bool
    event: PropTypes.func
    fullSize: PropTypes.bool
    onExit: PropTypes.func
    trigger: PropTypes.element.isRequired


  render: ->
    e '.modal-wrapper',
      e '.trigger', onClick: @toggleModal,
        @props.trigger
      if @state.show
        Modal
          eReader: @props.eReader
          fullSize: @props.fullSize
          onClose: (e) =>
            @toggleModal()
            @props.onExit?()
          @props.children


  toggleModal: ->
    @setState ({show}) ->
      @props.event?() unless show
      show: not show


module.exports = ModalWrapper
