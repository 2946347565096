import React from 'react';
import PropTypes from 'prop-types';
const style = require('./letter_composer_tabs.styl');
const cn = require('classnames/bind').bind(style);
import Modal from 'components/shared/modal';
import Button from 'components/shared/button';

const ModalPlanReset = ({onClose, onClickStartOver}) => {
  const isMobile = /Android|webOS|iPhone|BlackBerry/i.test(navigator.userAgent);
  return (
      <Modal top={true} onClose={onClose}>
        <div className={cn('modal')}>
          {
            isMobile ?
              <div>
                <div className={cn('modal-header')}>
                  {t('pageLetterComposer.modalStartOverTextHeader')}
                </div>
                <div className={cn('modal-text')}>
                  {t('pageLetterComposer.modalStartOverTextIphone')}
                </div>
              </div>
            :
            <div className={cn('modal-text')}>{t('pageLetterComposer.modalStartOverText')}</div>
          }
          <div className={cn('modal-buttons')}>
            <div className={cn('buttons')}>
              <div className={cn('button-left')}>
                <Button size='large' type='regular'
                        onClick={onClickStartOver}>
                  {t('pageLetterComposer.modalStartOverYes')}
                </Button>
              </div>
              <div className={cn('button-right')}>
                <Button mode='iphone' size='large' type='regular'
                        onClick={onClose}>
                  {t('pageLetterComposer.modalStartOverNo')}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
  );
};

ModalPlanReset.displayName = 'ModalPlanReset';
ModalPlanReset.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClickStartOver: PropTypes.func.isRequired,
};

export default ModalPlanReset;
