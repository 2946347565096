import React from "react";

const style = require('./header_js.styl');
const cn = require('classnames/bind').bind(style);
const {Component} = require('react');
const _ = require('lodash');

const Link = require('./Link');
class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModel: false ,
    };
  }

    _renderChild(child, i) {
        return (child ?
            <li
                className={cn('control',{disabled: (child.props.className === 'disabled')})}
                key={i}>
                {child}
            </li>
            : null
        );
    }

    _onClicklink(){
      this.setState({
        showModel: false
      });
      document.body.style.overflow = "initial";
    }
    _onClickSelect(){
      document.body.style.overflow = this.state.showModel ? "initial" : "hidden" ;
      this.setState({
        showModel: !this.state.showModel
      });
    }

    render() {
        const {children, className, links, onClickLogo, isUnauthenticated, messageCount} = this.props;
        const screenWidth = window.innerWidth;
        let isMobile = false;
        if (/Android|webOS|iPhone|BlackBerry/i.test(navigator.userAgent)) {
          isMobile = true;
        }

        return (
            <div className={isUnauthenticated ? cn('navbar-iphone') : cn('navbar')+" "+className}>
                <div className={isUnauthenticated ? cn('header-iphone') : cn('header')}>
                    <a onClick={onClickLogo}>
                        <img className={isUnauthenticated ? cn('logo-iphone') : cn('logo')}
                            alt={this.props.logoName}
                            src={this.props.logoUrl}>
                        </img>
                    </a>

                    <div className={ isUnauthenticated ? cn('header-controls-iphone') : cn('header-controls')}>
                      {
                        isMobile ?
                          <div className={ this.state.showModel ?  cn('iphone-drop-down-close') : (messageCount !== '0' ? cn('iphone-drop-down-red') : cn('iphone-drop-down'))} onClick={() => this._onClickSelect()}></div>
                          :
                          <ul className={cn('controls')}>
                            {_.map(children, this._renderChild)}
                          </ul>

                      }

                    </div>
                </div>
              {
                this.state.showModel ? <div className={cn('model-nav')}>
                  <div className={cn('line')}></div>
                  <ul className={cn('model-controls')}>
                    {children.map((item, index) => {
                      return (item ?
                          <li
                            className={cn('model-control',{disabled: (item.props.className === 'disabled')})}
                            key={index}
                            onClick={() => this._onClicklink()}>
                            {item}
                          </li>
                          : null
                      );
                    })}
                  </ul>
                </div> : null
              }


                {links.length > 0 &&
                <nav className={cn('links')}>
                    {_.map(links, (link,i) => <Link key={i} link={link}></Link>)}
                </nav>
                }
            </div>
        );
    }
}

Header.displayName = "Header";
module.exports = Header;
