({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './student_data_entry.styl'
e = require('react-e/bind') style
_ = require 'lodash'
Dropdown = createFactory require 'components/shared/dropdown'

GENDER_OPTIONS = [
  {label: t('studentDataEntry.male'), value: 'male'}
  {label: t('studentDataEntry.female'), value: 'female'}
  {label: t('studentDataEntry.nonbinary'), value: 'nonbinary'}
  {label: t('studentDataEntry.other'), value: 'other'}
]

STUDENT_DATA_FIELDS = [
  {key: 'firstname', label: t('studentDataEntry.firstName'), type: 'text'},
  {key: 'lastname', label: t('studentDataEntry.lastName'), type: 'text'},
  {key: 'gender', label: t('studentDataEntry.gender'), options: GENDER_OPTIONS, type: 'dropdown'},
  {key: 'username', label: t('studentDataEntry.username'), type: 'text'},
  {key: 'password', label: t('studentDataEntry.password'), type: 'password'}
]

PARENT_DATA_FIELDS = [
  {key: 'parentname', label: t('studentDataEntry.parentName'), type: 'text'},
  {key: 'parentemail', label: t('studentDataEntry.parentEmail'), type: 'text'}
]

class StudentDataEntry extends Component

  @propTypes: do ->
    MapOf = (type) -> PropTypes.shape
      firstname: type
      gender: type
      lastname: type
      parentemail: type
      parentname: type
      password: type
      username: type

    data: MapOf(PropTypes.string).isRequired
    enabled: MapOf(PropTypes.bool).isRequired
    errors: MapOf(PropTypes.string).isRequired
    onChange: PropTypes.func.isRequired
    permissionFormType: PropTypes.string.isRequired
    userId: PropTypes.string


  _updateStudent: (value, key) =>
    updatedData = @props.data
    updatedData[key] = value
    @props.onChange updatedData


  render: ->
    e '.studentForm',
      e '.row', STUDENT_DATA_FIELDS.map @renderRow
        if @props.permissionFormType is 'esign'
          [
            e '.row', key: 'parentReason', t 'studentDataEntry.parentreason'
            e '.row', key: 'parentFields', PARENT_DATA_FIELDS.map @renderRow
          ]

  renderRow: (fields) =>
    {key, label, type} = fields
    error = @props.errors[key]
    enabled = @props.enabled[key]
    value = @props.data[key]
    e 'label.item', key: key,
      e '.label', label
      switch type
        when 'dropdown'
          e '.dropdown-container',
            Dropdown
              disabled: not enabled
              onChange: (data) => @_updateStudent data?.value, key
              options: fields.options
              value: value or null
        else
          e '.input-container',
            e 'input',
              className: key
              disabled: not enabled
              onChange: (e) => @_updateStudent e.target.value, key
              type: type
              value: value or ''
      e '.error', className: {error_show: error?}, error

StudentDataEntry.displayName = "StudentDataEntry"

module.exports = StudentDataEntry
